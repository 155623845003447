import $ from "jquery";
import {uploadImage, uploadToDropbox} from "./api";
import {
  createTransformedCanvas,
  fixRuler,
  updateCanvas,
  updateDepth,
  updateDimensions,
  updateInputs,
  updateRepeatX,
  updateRepeatY
} from "./canvas_DONE";
import {constants, updateConstants} from "./constants";
import {getElement} from "./html-elements-selector";
import {canvasToBlob, cm2pixel, isFile, loadImage, pixel2cm} from "./utility_DONE";

export async function getFinalJson() {
  calculateFabricLength();
  constants.uuid && await createThumbnail();
  return {
    finalJsonForDropbox: {
      cartID: constants.cartID,
      // cartItemID: constants.cartItemID,
      productID: constants.productID,
      fabricWidth: constants.fabricWidth,
      fabricLength: constants.fabricLength,
      print_option: constants.print_option.name,
      uuid: constants.uuid,
      images: constants.images.map(image => ({
        full_file_name: image.imageObj.full_file_name,
        file_size: image.imageObj.file_size,
        aspect_ratio: image.imageObj.aspect_ratio,
        offset_x: image.imageObj.offset_x,
        offset_y: image.imageObj.offset_y,
        ppi: image.imageObj.ppi,
        cm_width: image.imageObj.cm_width,
        cm_height: image.imageObj.cm_height,
        cm_repeat_y: image.imageObj.cm_repeat_y,
        cm_repeat_x: image.imageObj.cm_repeat_x,
        repeat_space_x: image.imageObj.repeat_space_x,
        repeat_space_y: image.imageObj.repeat_space_y,
        repeat_style: image.imageObj.repeat_style,
        repeat_offset: `1/${image.imageObj.repeat_offset}`,
        rotate: image.imageObj.rotate,
        flip: image.imageObj.flip,
        depth: image.imageObj.depth,
      }))
    },
    finalJsonForWP: {
      cartItemID: constants.cartItemID,
      productID: constants.productID,
      fabricWidth: constants.fabricWidth,
      fabricLength: constants.fabricLength,
      print_option: constants.print_option,
      uuid: constants.uuid,
      images: constants.images.map(image => image.imageObj)
    }
    
  }
  
}

function newImageAddEventListeners(imageElement) {
  const imagesContainer = getElement('imagesContainer');
  const $mainImageSelector = $(getElement('mainImageSelector'));
  
  //region select image
  imageElement.addEventListener('click', () => {
    for (let child of imagesContainer.children) {
      child.classList.remove('image-active')
    }
    imageElement.classList.add('image-active');
    constants.currentImage = imageElement;
    updateInputs(imageElement)
    $mainImageSelector
        .val(imageElement.imageObj.file_name)
        .trigger('change')
  });
  //endregion select image
  
  //region drag image
  imageElement.addEventListener('mousedown', function (e) {
    if (e.target.classList.contains('control')) return;
    const dragX = e.clientX - imageElement.offsetLeft;
    const dragY = e.clientY - imageElement.offsetTop;
    
    function move(e) {
      imageElement.imageObj.pixel_offset_x = e.clientX - dragX;
      imageElement.imageObj.pixel_offset_y = e.clientY - dragY;
      
      imageElement.style.left = imageElement.imageObj.pixel_offset_x + 'px';
      imageElement.style.top = imageElement.imageObj.pixel_offset_y + 'px';
      
      imageElement.imageObj.offset_x = pixel2cm(imageElement.imageObj.pixel_offset_x).toFixed(1);
      imageElement.imageObj.offset_y = pixel2cm(imageElement.imageObj.pixel_offset_y).toFixed(1);
      
      updateInputs(imageElement);
    }
    
    function stopMove() {
      document.removeEventListener('mousemove', move);
      document.removeEventListener('mouseup', stopMove);
      
    }
    
    document.addEventListener('mousemove', move);
    document.addEventListener('mouseup', stopMove);
  });
  
  //endregion drag image
  
  function handleResize({
                          selector, onResize, minimumSize = 15
                        }) {
    imageElement.querySelector(selector)
        .addEventListener('mousedown', function (e) {
          e.preventDefault();
          const rect = imageElement.getBoundingClientRect();
          
          function resize(e) {
            // Apply the provided resize logic
            onResize(e, rect);
            
            updateInputs(imageElement);
            updateCanvas(imageElement);
          }
          
          function stopResize() {
            document.removeEventListener('mousemove', resize);
            document.removeEventListener('mouseup', stopResize);
          }
          
          document.addEventListener('mousemove', resize);
          document.addEventListener('mouseup', stopResize);
        });
  }
  
  handleResize({
    selector: '.master-size', onResize: (e, rect) => {
      const height = e.clientY - rect.top;
      const width = height * imageElement.imageObj.aspect_ratio;
      if (width < 15 || height < 15) return
      const cmWidth = pixel2cm(width);
      const cmHeight = pixel2cm(height);
      updateDimensions(imageElement, cmWidth, cmHeight)
    }
  });
  
  handleResize({
    selector: '.right-size', onResize: (e, rect) => {
      const width = e.clientX - rect.left;
      if (width < 15) return
      const cmWidth = pixel2cm(width);
      
      
      updateRepeatX(imageElement, cmWidth)
    }
  });
  
  handleResize({
    selector: '.bottom-size', onResize: (e, rect) => {
      const height = e.clientY - rect.top;
      if (height < 15) return
      const cmHeight = pixel2cm(height);
      updateRepeatY(imageElement, cmHeight)
    }
  });
  
}

function processImageElement(imageElement) {
  constants.images.push(imageElement);
  newImageAddEventListeners(imageElement);
  imageElement.click();
  updateInputs(imageElement);
  updateCanvas(imageElement);
  updateDepth();
}

async function initImageFromFile(imageFile) {
  
  const randomImageId = Math.random().toString(36).slice(2, 10);
  const parts = imageFile.name.split(".");
  const extension = parts.pop();
  const fileName = parts.join(".");
  const fileNameObj = {
    file_extension: extension,
    file_name: `${fileName}__${randomImageId}`,
    full_file_name: `${fileName}__${randomImageId}.${extension}`,
    id: randomImageId,
  }
  const urls = (await uploadImage({
        full_file_name: fileNameObj.full_file_name,
        file_name_without_extension: fileNameObj.file_name,
        imageFile
      }))
      ?? {
        ppi: 0,
        wp_url: '',
        dropbox_url: ''
      }
  
  const image = await loadImage(urls.wp_url);
  
  const [pixel_width, pixel_height] = [image.naturalWidth, image.naturalHeight]
  const transformedCanvas = createTransformedCanvas(image, pixel_width, pixel_height);
  
  return createImageObj({
    urls,
    fileNameObj,
    image,
    imageFile,
    pixelWidth: pixel_width,
    pixelHeight: pixel_height,
    transformedCanvas
  });
}

function createImageObj({
                          urls: {wp_url, dropbox_url, ppi: server_ppi},
                          fileNameObj,
                          image,
                          imageFile,
                          pixelWidth,
                          pixelHeight,
                          transformedCanvas
                        }) {
  const {now, default_ppi} = constants;
  
  let ppi = server_ppi || default_ppi;
  let cmWidth = (pixelWidth / ppi * 2.54).toFixed(1);
  let cmHeight = (pixelHeight / ppi * 2.54).toFixed(1);
  if (!server_ppi && cmWidth > constants.fabricWidth - 4) {
    cmWidth = (constants.fabricWidth - 4).toFixed(1);
    ppi = pixelWidth / cmWidth * 2.54;
    cmHeight = (pixelHeight / ppi * 2.54).toFixed(1);
  }
  return {
    image, transformedCanvas, imageObj: {
      ...fileNameObj,
      created_at: now,
      file_updated_at: now,
      updated_at: now,
      file_content_type: imageFile.type,
      original_file_name: imageFile.name,
      file_size: imageFile.size,
      pixel_width: pixelWidth,
      pixel_height: pixelHeight,
      aspect_ratio: pixelWidth / pixelHeight,
      wp_url,
      dropbox_url,
      pixel_offset_x: cm2pixel(2),
      pixel_offset_y: 0,
      offset_x: 2,
      offset_y: 0,
      ppi,
      cm_width: cmWidth,
      cm_height: cmHeight,
      cm_repeat_y: cmHeight,
      cm_repeat_x: cmWidth,
      repeat_space_x: 0,
      repeat_space_y: 0,
      repeat_style: 'normal',
      repeat_offset: '2',
      rotate: '0',
      is_90_270: false,
      flip: 'n',
      depth: 0,
    }
  };
}

export async function createImageElement(imageFileOrObj) {
  const {
    transformedCanvas, image, imageObj
  } = isFile(imageFileOrObj)
      ? await initImageFromFile(imageFileOrObj)
      : await initImageWithImageObj(imageFileOrObj);
  
  const imagesContainer = getElement('imagesContainer');
  imagesContainer.appendChild(document.importNode(getElement('imageTemplate').content, true));
  
  const imageElement = imagesContainer.lastElementChild
  imageElement.style.width = cm2pixel(imageObj.cm_repeat_x) + 'px';
  imageElement.style.height = cm2pixel(imageObj.cm_repeat_y) + 'px';
  imageElement.style.setProperty("--master-width", cm2pixel(imageObj.cm_width) + 'px');
  imageElement.style.setProperty("--master-height", cm2pixel(imageObj.cm_height) + 'px');
  const canvas = imageElement.querySelector('canvas');
  Object.assign(imageElement, {
    imageObj, transformedCanvas, image, canvas, dataURL: transformedCanvas.toDataURL("image/webp", 0.3)
  });
  const newOption = new Option(imageElement.imageObj.original_file_name, imageElement.imageObj.file_name, true, true);
  newOption.imageElement = imageElement;
  $(getElement('mainImageSelector'))
      .append(newOption)
      .trigger('change');
  processImageElement(imageElement);
  
}

export async function generateCanvasFromJson(jsonImages) {
  const obj = JSON.parse(jsonImages);
  const path = window.location.pathname;
  const segments = path.split('/').filter(segment => segment !== ''); // This filters out any empty strings
  
  obj.cartItemID = segments[segments.length - 1]; // This will get "3011750b7ae311f0849611fcc330a449"
  
  updateConstants(obj);
  fixRuler();
  for (const imageObj of obj.images) {
    await createImageElement(imageObj)
  }
  constants.images.map(updateInputs)
  
  return obj
}

async function initImageWithImageObj(imageObj) {
  const {wp_url, pixel_width, pixel_height} = imageObj;
  const image = await loadImage(wp_url);
  const transformedCanvas = createTransformedCanvas(image, pixel_width, pixel_height);
  return {image, transformedCanvas, imageObj}
}

export function calculateFabricLength() {
  let maxLength = 0;
  
  constants.images.forEach(({imageObj}) => {
    
    
    const imageBottomEdge = +imageObj.cm_repeat_y + +imageObj.offset_y;
    
    if (imageBottomEdge > maxLength) {
      maxLength = imageBottomEdge;
    }
    
  });
  
  constants.fabricLength = Math.max(maxLength, 1);
}

async function createThumbnail() {
  const thumbnailScaleFactor = 0.5; // Scaling factor for the thumbnail
  
  const {fabricWidth, fabricLength, images} = constants;
  // Create canvas for the full image
  const fullImageCanvas = document.createElement('canvas');
  fullImageCanvas.width = fabricWidth * 25;
  fullImageCanvas.height = (fabricLength ?? 1) * 25;
  const ctx = fullImageCanvas.getContext('2d');
  images
      .sort((a, b) => a.depth - b.depth)
      .map(image => {
        // Scale the positions and dimensions according to the scaleFactor
        const xPos = image.imageObj.offset_x * 25;
        const yPos = image.imageObj.offset_y * 25;
        const width = image.imageObj.cm_repeat_x * 25;
        const height = image.imageObj.cm_repeat_y * 25;
        
        // Draw the image on the canvas
        ctx.drawImage(image.canvas, xPos, yPos, width, height);
      });
  const fullImageFile = new File([await canvasToBlob(fullImageCanvas)], 'full-image.webp', {type: 'image/webp'});
  const urls = await uploadToDropbox(fullImageFile, 'full-image.webp', 'final-thumbnail', false)
  constants.fullImageURL = urls.dropbox_url;
  constants.finalThumbnailURL = urls.wp_url;
}

function filterObjectProperties(imageObject) {
  const keysToKeep = [
    "file_content_type",
    "file_extension",
    "full_file_name",
    "file_size",
    "id",
    "pixel_width",
    "pixel_height",
    "aspect_ratio",
    "wp_url",
    "dropbox_url",
    "pixel_offset_x",
    "pixel_offset_y",
    "offset_x",
    "offset_y",
    "ppi",
    "cm_width",
    "cm_height",
    "cm_repeat_x",
    "cm_repeat_y",
    "repeat_space_x",
    "repeat_space_y",
    "repeat_style",
    "repeat_offset",
    "rotate",
    "is_90_270",
    "flip",
    "depth"
  ];
  
  const filteredObject = {};
  
  Object.keys(imageObject).forEach(key => {
    if (keysToKeep.includes(key)) {
      filteredObject[key] = imageObject[key];
    }
  });
  
  return filteredObject;
}

function stringifyFilteredImageObjList() {
  return JSON.stringify(constants.images.map(({imageObj}) => filterObjectProperties(imageObj)));
}

