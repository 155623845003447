import {cm2pixel} from "./utility_DONE";

export const constants = {
  layoutHeight: 500,
  cartItemID: 0,
  cartID: wc_object.cart_id,
  fabricWidth: 140,
  fabricLength: 1,
  finalThumbnailURL: '',
  print_option: {
    name: 'pigment',
    slug: 'pigment'
  },
  minPPI: 50,
  productID: 0,
  uuid: 0,
  price: 0,
  cmFactor: 15,
  dpr: window.devicePixelRatio || 1,
  currentImage: {},
  images: [],
  repeatStylesCanvasFunctions: {
    normal({transformedCanvas, canvas, imageObj}) {
      const ctx = canvas.getContext("2d");
      const {
        cm_width,
        cm_height,
        cm_repeat_x,
        cm_repeat_y,
        repeat_space_x,
        repeat_space_y,
      } = imageObj;
      
      for (let i = 0; i < +cm_repeat_x / (+cm_width + +repeat_space_x); i++) {
        for (let j = 0; j < +cm_repeat_y / (+cm_height + +repeat_space_y); j++) {
          ctx.drawImage(transformedCanvas, i * cm2pixel(+cm_width + +repeat_space_x), j * cm2pixel(+cm_height + +repeat_space_y), cm2pixel(+cm_width), cm2pixel(+cm_height));
        }
      }
      
    },
    brick({transformedCanvas, canvas, imageObj}) {
      const ctx = canvas.getContext("2d");
      const {
        cm_width,
        cm_height,
        cm_repeat_x,
        cm_repeat_y,
        repeat_space_x,
        repeat_space_y,
        repeat_offset,
      } = imageObj;
      for (let j = 0; j <= +cm_repeat_y / (+cm_height + +repeat_space_y); j++) {
        for (let i = -1; i <= +cm_repeat_x / (+cm_width + +repeat_space_x); i++) {
          ctx.drawImage(
              transformedCanvas,
              i * cm2pixel(+cm_width + +repeat_space_x) + (j % +repeat_offset) / +repeat_offset * cm2pixel(+cm_width),
              j * cm2pixel(+cm_height + +repeat_space_y),
              cm2pixel(+cm_width),
              cm2pixel(+cm_height)
          );
        }
      }
      
      
    },
    drop({transformedCanvas, canvas, imageObj}) {
      const ctx = canvas.getContext("2d");
      const {
        cm_width,
        cm_height,
        cm_repeat_x,
        cm_repeat_y,
        repeat_space_x,
        repeat_space_y,
        repeat_offset,
      } = imageObj;
      for (let i = 0; i <= +cm_repeat_x / (+cm_width + +repeat_space_x); i++) {
        for (let j = -1; j <= +cm_repeat_y / (+cm_height + +repeat_space_y); j++) {
          ctx.drawImage(
              transformedCanvas,
              i * cm2pixel(+cm_width + +repeat_space_x),
              j * cm2pixel(+cm_height + +repeat_space_y) + (i % +repeat_offset) / +repeat_offset * cm2pixel(+cm_height),
              cm2pixel(+cm_width),
              cm2pixel(+cm_height)
          );
        }
      }
      
      
    },
  },
  now: new Date(),
  default_ppi: 50,
}

export function updateConstants({images, ...props}){
  // Loop over props and assign to constants
  for (let key in props) {
    constants[key] = props[key];
  }
  constants.images=[];
  
}
