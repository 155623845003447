import {fixRuler} from "../canvas_DONE";
import {constants} from "../constants";
import {openModal} from "../modal_DONE";
import {getElement, getElements} from "../html-elements-selector";
import {saveCartItem} from "../api";
import {hideLoader, showLoader} from "../utility_DONE";
import $ from "jquery";


export default function (selectedProductId) {
  const products = getElements('products');
  const $products = $(products);
  
  let selectedPrintOption = {option:''};
  
  $('#printOptionsFilter').on('change', function () {
    
    selectedPrintOption.option = this.value;
    if (selectedPrintOption.option) {
      $products
          .hide()
          .filter(function () {
            return $(this).data('print-options').split(',').includes(selectedPrintOption.option);
          })
          .show();
    } else {
      $products.show();
    }
  });
  
  
  const modalSelectFabricButton = getElement('modal')?.querySelector('.select-fabric');
  let selectedProduct;
  let openedProduct;
  
  
  products.forEach(product => {
    const productId = product.dataset.productId;
    
    if (selectedProductId === productId) {
      selectedProduct = product
      product.classList.add('selected')
    }
    product.addEventListener("click", function () {
      const modalTemplate = product.querySelector(".product-content");
      const productStatus = product.dataset.productStatus;
      // Check the product status and enable/disable the button accordingly
      if (productStatus === 'unselectable') {
        modalSelectFabricButton.disabled = true;
        modalSelectFabricButton.title = "This fabric is not selectable due to missing width information.";
      } else {
        modalSelectFabricButton.disabled = false;
        modalSelectFabricButton.title = ""; // Clear the title if the product is selectable
      }
      openedProduct = product
      openModal(modalTemplate, productId, selectedPrintOption);
    });
  });
  
  
  const selectFabricButton = getElement('goToStep2');
  const sendFabricToPrintBtn = document.getElementById('sendFabricToPrintBtn');
  const loaderContainer = document.querySelector('#custom-modal .spinner-loader-container');
  const stepsWrapper = getElement('stepsWrapper');
  const stepTwoItem = getElement('stepTwoItem');
  const stepsLabel = getElement('stepsLabel');
  const tabSelectFabric = stepsLabel.querySelector('.item-1');
  const tabEdit = stepsLabel.querySelector('.item-3');
  tabSelectFabric.style.cursor = 'pointer';
  tabSelectFabric.addEventListener('click', function () {
    tabEdit?.classList.remove('active');
    tabSelectFabric?.classList.add('active');
    stepsLabel?.classList.remove('step-2-active', 'step-3-active');
    stepsWrapper?.classList.remove('taps-2-content-active', 'taps-3-content-active');
    stepsWrapper?.classList.add('taps-1-content-active');
  });
  
  if (selectedProductId) {
    tabEdit.style.cursor = 'pointer';
    tabEdit.addEventListener('click', function () {
      tabSelectFabric?.classList.remove('active');
      tabEdit?.classList.add('active');
      stepsLabel?.classList.add('step-3-active');
      stepsWrapper?.classList.add('taps-3-content-active');
      stepsWrapper?.classList.remove('taps-1-content-active');
    });
  }
  
  selectFabricButton?.addEventListener("click", function () {
    // Disable the button to prevent multiple clicks
    const modalFabricWidth = getElement('modal')?.querySelector('[data-fab-width]');
    
    // Show the loader
    const selectedPrintOption = getElement('selectPrintOptions')?.value;
    if (!selectedPrintOption) {
      alert('please select a print option');
      return
    }
    constants.fabricWidth = +(modalFabricWidth?.dataset.fabWidth || '100')
    selectFabricButton.disabled = true;
    const [id, name] = selectedPrintOption.split('%^&*#@!');
    constants.print_option = {id, name}
    
    selectedProduct?.classList.remove('selected')
    selectedProduct = openedProduct;
    selectedProduct?.classList.add('selected')
    openedProduct = null;
    
    if (selectedProductId || constants.created) {
      showLoader("Selecting fabric...");
      
      saveCartItem()
          .then(() => {
            window.history.replaceState({}, '', `/${BASE_URL}edit-design/${constants.cartItemID}`)
            selectFabricButton.disabled = false;
            hideLoader();
            document.documentElement.classList.remove("modal-opened")
            getElement('modal')?.classList.remove("modal-active")
            tabSelectFabric?.classList.remove('active');
            tabEdit?.classList.add('active');
            stepsLabel?.classList.add('step-3-active');
            stepsWrapper?.classList.add('taps-3-content-active');
            stepsWrapper?.classList.remove('taps-1-content-active');
            fixRuler();
          })
          .catch((error) => {
            // Handle any errors or rejections from the saveCartItem function
            console.log(error)
            alert(error);
            
            // Re-enable the button after failed AJAX request
            selectFabricButton.disabled = false;
            
            // Hide the loader
            hideLoader();
          });
      
      return
    }
    
    // Update send fabric to print anchor with the selected fabric id
    sendFabricToPrintBtn?.setAttribute('href', sendFabricToPrintBtn.getAttribute('href') + '?product_id=' + constants.productID);
    selectFabricButton.disabled = false;
    hideLoader();
    document.documentElement.classList.remove("modal-opened")
    getElement('modal')?.classList.remove("modal-active")
    stepTwoItem?.classList.add('active');
    stepsLabel?.classList.add('step-2-active');
    stepsWrapper?.classList.add('taps-2-content-active');
    stepsWrapper?.classList.remove('taps-1-content-active');
    
  });
  
}

