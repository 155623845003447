import {constants} from "./constants";
import {getElement} from "./html-elements-selector";
import {allowPageScroll} from "../functions/prevent_allowPageScroll";
import $ from "jquery";
import "select2";


export const closeModal = () => {
  document.documentElement.classList.remove("modal-opened")
  getElement('modal')?.classList.remove("modal-active")
  constants.productID = null;
}

export const openModal = (modalTemplate, productID, {option}) => {
  if (!modalTemplate) return;
  constants.productID = productID;
  getElement('customModalContent').innerHTML = modalTemplate.innerHTML;
  getElement('modal')?.classList.add("modal-active");
  document.documentElement.classList.add("modal-opened");
  
  const $select = $(getElement('customModalContent').querySelector('select'));
  $select.select2(
      {
        placeholder: "Choose a print option",
        allowClear: false
      }
  ).on('change', e => getElement('selectPrintOptions').value = e.target.value)
  if (option) {
    const val = $select.find(`option[value$="${option.slice(1)}"]`).val();
    $select.val(val).trigger('change')
  }
  
  
}

export function initModal() {
  const customModalEl = document.querySelector('#custom-modal');
  if (customModalEl) {
    const customModalInner = customModalEl.querySelector('.custom-modal-inner');
    const closeModalElm = customModalInner.querySelector('.close-modal');
    
    const closeModal = (e) => {
      // region allow page scroll
      allowPageScroll()
      // endregion allow page scroll
      customModalEl.classList.remove('modal-active');
      constants.productID = null;
    }
    
    const keyHandler = (e) => {
      if (e.key === 'Escape') {
        closeModal();
      }
    }
    window.addEventListener('keydown', keyHandler)
    
    customModalEl?.addEventListener('click', closeModal);
    customModalInner?.addEventListener('click', e => e.stopPropagation());
    closeModalElm?.addEventListener('click', closeModal);
  }
}
