/**
 * Object to store cached DOM elements.
 * @type {Object.<string, HTMLElement>}
 */
const elementsCache = {};

/**
 * A mapping of keys to selector strings.
 * Each entry maps a key to a selector used to retrieve the element from the DOM.
 * @type {Object.<string, string>}
 */
export const elementSelectors = {
  rulerX: '.ruler-x',
  rulerY: '.ruler-y',
  addToCart: '.add-to-cart-btn',
  stepsWrapper: '.steps-wrapper',
  uploadFabric: '#upload-fabric',
  products: '.woocommerce-product',
  close: '.close-wrapper',
  customModalContent: '#custom-modal .custom-modal-content',
  fabricWidthElement: '.width',
  formCreate: '.form-create',
  goToStep2: '#custom-modal .select-fabric',
  imageTemplate: '.default-image-element',
  imagesContainer: '.layout',
  input_cm_height: '#cm_height',
  input_cm_width: '#cm_width',
  input_depth_backward: '.depth-backward',
  input_depth_forward: '.depth-forward',
  inputs: 'form.form-create input, form.form-create select, form.form-create textarea',
  layoutWidthElement: '.layout',
  mainImageSelector: '#image-selected',
  modal: '#custom-modal',
  ppiFormRow: '.form-row.ppi-form-row',
  productIdInput: '#selected_product_id',
  selectDesign: '.step-2',
  selectPrintOptions: '.select-print-options',
  selectedProductInput: '#selected_product_id',
  sideMenu: '.icon-menu',
  stepThreeItem: '.item-3',
  stepTwoItem: '.item-2',
  stepThreeDeleteBtn: '.create-content button.delete',
  stepThreeRemoveBtn: '.create-content button.remove',
  stepThreeUploadBtn: '.create-content .btn-upload',
  stepThreeSaveBtn: '.create-content button.add-to-cart-btn',
  stepThreeRemovePopup: '.create-content button.add-to-cart-btn',
  stepsLabel: '.tabs-label',
  tabsBlock: '.tabs_block',
  fabricWidth:'.fabric-width',
};


/**
 * Get a DOM element and cache it for future use.
 *
 * @param {string} elementKey - The key used to identify the element.
 * @returns {HTMLElement|null} - The DOM element or null if it doesn't exist.
 */
export const getElement = (elementKey) => {
  // If the element is cached, return it
  if(elementsCache[elementKey]) {
    return elementsCache[elementKey];
  }

  // Get the selector for the element
  const selector = elementSelectors[elementKey];

  // If there's no selector, return null
  if (!selector) {
    console.warn(`No selector found for element key: "${elementKey}"`);
    return null;
  }

  // Select the element from the DOM
  const element = document.querySelector(selector);

  // If the element is found, cache and return it
  if (element) {
    elementsCache[elementKey] = element;
  } else {
    console.warn(`No element found in the DOM for selector: "${selector}"`);
  }

  return element;
};

/**
 * Get all matching DOM elements and cache them for future use.
 *
 * @param {string} elementKey - The key used to identify the elements based on a selector.
 * @returns {NodeList|null} - A NodeList of matching DOM elements or null if none exist.
 */
export const getElements = (elementKey) => {
  // If the elements are cached, return them
  if (elementsCache[elementKey]) {
    return elementsCache[elementKey];
  }
  
  // Get the selector for the elements
  const selector = elementSelectors[elementKey];
  
  // If there's no selector, return null
  if (!selector) {
    console.warn(`No selector found for element key: "${elementKey}"`);
    return null;
  }
  
  // Select the elements from the DOM
  const elements = document.querySelectorAll(selector);
  
  // If any elements are found, cache and return them
  if (elements.length) {
    elementsCache[elementKey] = elements;
  } else {
    console.warn(`No elements found in the DOM for selector: "${selector}"`);
  }
  
  return elements;
};

const addEventListeners = ()=>{

  document.querySelector('button.delete').addEventListener('click', function (e) {
    $('[data-popup="popup-delete-cart-item"]').fadeIn(350);
  });

  document.querySelector('button.remove').addEventListener('click', function (e) {
    $('[data-popup="popup-remove-image"]').fadeIn(350);
  });
}
