import {constants} from "./constants";
import {gsap} from 'gsap'

export function cm2pixel(cm) {
  return cm * constants.cmFactor;
}

export function pixel2cm(pixel) {
  return pixel / constants.cmFactor;
}

export function loadImage(url) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = url;
    image.onload = () => resolve(image);
    image.onerror = reject;
    
  })
}

export function canvasToBlob(canvas) {
  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
      blob ? resolve(blob) : reject(new Error('Canvas to Blob conversion failed'));
    }, 'image/webp');
  });
}

export function isFile(imageFile) {
  return imageFile instanceof File || imageFile instanceof Blob;
}

// Function to show the loader
export function showLoaderold(text = 'Loading...') {
  const loaderContainer = document.getElementById('loading-container')
  if (!loaderContainer) return;
  loaderContainer.classList.add('active');
  const loaderText = loaderContainer.querySelector('.text');
  loaderContainer.setAttribute('aria-hidden', 'false');
  if (loaderText) {
    loaderText.textContent = text;
  }
}

// Function to hide the loader
export function hideLoaderpld(doNext) {
  const loaderContainer = document.getElementById('loading-container')
  if (!loaderContainer) return;
  loaderContainer.classList.add('done');
  setTimeout(() => {
    loaderContainer.classList.remove('active', 'done');
    loaderContainer.setAttribute('aria-hidden', 'true');
    doNext?.();
  }, 1000)
}


// Function to show the loader
let animation;
export function showLoader(text = 'Loading') {
  const loaderContainer = document.getElementById('loading-container');
  if (!loaderContainer) return;
  loaderContainer.classList.add('active');
  const loaderText = loaderContainer.querySelector('.text');
  loaderContainer.setAttribute('aria-hidden', 'false');
  if (loaderText) {
    loaderText.textContent = text;
  }
  
  const progressCircle = loaderContainer.querySelector('.progress');
  animation= gsap.fromTo(progressCircle,{strokeDashoffset: 251.33}, {
    overwrite: true,
    duration: 600,
    strokeDashoffset: 0,
    ease: 'none',
    onUpdate()  {
      // console.log(this.progress().toFixed(1));
      loaderText.textContent = `${(this.progress()*100).toFixed()}%`;
    },
  });
}

// Function to hide the loader
export function hideLoader(doNext,text = 'Loading') {
  const loaderContainer = document.getElementById('loading-container');
  if (!loaderContainer) return;
  const loaderText = loaderContainer.querySelector('.text');
  const progressCircle = loaderContainer.querySelector('.progress');
  animation?.pause();
  gsap.to(progressCircle, {
    duration: 2,
    strokeDashoffset: 0,
    ease: 'none',
    clearProps: 'all',
    onUpdate()  {
      // console.log(this.progress().toFixed(1));
      loaderText.textContent = `${(this.progress()*100).toFixed()}%`;
    },
    onComplete(){
      loaderContainer.classList.remove('active', 'done');
      loaderContainer.setAttribute('aria-hidden', 'true');
      doNext?.();
    }
  });
}


// Function to show the loader
export function showLoader_old(loaderContainer, text = 'Loading...') {
  
  if (!loaderContainer) return;
  loaderContainer.classList.add('visible');
  const spinnerLoader = loaderContainer.querySelector('.spinner-loader');
  const loaderText = loaderContainer.querySelector('.spinner-loader-text');
  spinnerLoader.setAttribute('aria-hidden', 'false');
  if (loaderText) {
    loaderText.textContent = text;
  }
}

// Function to hide the loader
export function hideLoader_old(loaderContainer) {
  if (!loaderContainer) return;
  loaderContainer.classList.remove('visible');
  const spinnerLoader = loaderContainer.querySelector('.spinner-loader');
  const loaderText = loaderContainer.querySelector('.loader-text');
  spinnerLoader.setAttribute('aria-hidden', 'true');
  if (loaderText) {
    loaderText.textContent = '';
  }
}
