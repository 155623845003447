import $ from "jquery";
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import "select2";
import {debounce} from "../../functions/debounce";
import {deleteCartItem, deleteImage, saveCartItem} from "../api";
import {
  fixRuler,
  updateCanvas,
  updateDepth,
  updateDimensions,
  updateInputs,
  updateRepeatX,
  updateRepeatY
} from "../canvas_DONE";
import {constants} from "../constants";
import {getElement} from "../html-elements-selector";
import {createImageElement, generateCanvasFromJson} from "../image_DONE";
import {hideLoader, showLoader} from "../utility_DONE";

export default function () {
  $(".form-row .icon-and-text").each(function () {
    const content = $(this).data("popover-content");
    if (content) {
      tippy(this, {
        content: content,
        placement: 'left',
        animation: 'scale',
        arrow: true,
        theme: 'custom',
        offset: [0, 10],
        onShow(instance) {
          if (!$(instance.reference).data("popover-content")) {
            return false;  // Prevents showing if there's no content
          }
        }
      });
    }
  });
  //region select2
  $(getElement('mainImageSelector'))
      .select2({
        minimumResultsForSearch: -1,
        templateResult: option => !option.id ? option.text : $(`<img style="height:auto;width:300px;" src="${option.element.imageElement.dataURL}"/>`),
        templateSelection: option => !option.id ? option.text : $(`<img style="height: 100px; width: 250px; object-fit: contain;" src="${option.element.imageElement.dataURL}"/>`),
        placeholder: 'Select images',
      }).on('select2:select', function (e) {
    e?.params?.data?.element?.imageElement?.click();
  });
  $('select:not(#image-selected)')
      .select2({minimumResultsForSearch: -1})
      .on('select2:select', function () {
        this.dispatchEvent(new Event('change'))
      })
  //endregion select2
  
  
  // region form inputs event listeners
  
  let input_cm_width = getElement('input_cm_width');
  let input_cm_height = getElement('input_cm_height');
  let input_depth_forward = getElement('input_depth_forward');
  let input_depth_backward = getElement('input_depth_backward');
  
  const inputs = document.querySelectorAll('form.form-create input, form.form-create select , form.form-create textarea');
  inputs.forEach(input => {
    const inputName = input.getAttribute('name');
    input.addEventListener('change', () => {
      if (inputName && inputName in constants.currentImage.imageObj) {
        if (['cm_width', 'cm_height', 'cm_repeat_x', 'cm_repeat_y', 'repeat_space_x', 'repeat_space_y', 'offset_x', 'offset_y'].includes(inputName)) {
          constants.currentImage.imageObj[inputName] = +input.value;
        } else {
          constants.currentImage.imageObj[inputName] = input.value;
        }
        
        
        if (inputName === 'cm_width') {
          input_cm_height.value = +input.value / constants.currentImage.imageObj.aspect_ratio;
          updateDimensions(constants.currentImage, +input.value, +input_cm_height.value)
        }
        if (inputName === 'cm_height') {
          input_cm_width.value = +input.value * constants.currentImage.imageObj.aspect_ratio;
          updateDimensions(constants.currentImage, +input_cm_width.value, +input.value)
        }
        if (inputName === 'cm_repeat_x') {
          updateRepeatX(constants.currentImage, +input.value)
        }
        if (inputName === 'cm_repeat_y') {
          updateRepeatY(constants.currentImage, +input.value)
        }
        if (inputName === 'rotate') {
          if ([90, 270].includes(+input.value) && !constants.currentImage.is_90_270) {
            constants.currentImage.is_90_270 = true;
            updateDimensions(constants.currentImage, +input_cm_height.value, +input_cm_width.value)
            constants.currentImage.imageObj.aspect_ratio = 1 / constants.currentImage.imageObj.aspect_ratio;
          }
          if ([0, 180].includes(+input.value) && constants.currentImage.is_90_270) {
            constants.currentImage.is_90_270 = false;
            updateDimensions(constants.currentImage, +input_cm_height.value, +input_cm_width.value)
            constants.currentImage.imageObj.aspect_ratio = 1 / constants.currentImage.imageObj.aspect_ratio;
          }
        }
        
        updateInputs(constants.currentImage)
        updateCanvas(constants.currentImage)
      }
    })
  });
  
  input_depth_forward.addEventListener('click', () => {
    const currentDepth = constants.currentImage.imageObj.depth;
    const images = constants.images;
    if (currentDepth === images.length - 1) return;
    [images[currentDepth], images[currentDepth + 1]] = [images[currentDepth + 1], images[currentDepth]];
    updateDepth();
    input_depth_backward.disabled = constants.currentImage.imageObj.depth === 0;
    input_depth_forward.disabled = constants.currentImage.imageObj.depth === images.length - 1;
  })
  
  input_depth_backward.addEventListener('click', () => {
    const currentDepth = constants.currentImage.imageObj.depth;
    const images = constants.images;
    if (currentDepth === 0) return;
    [images[currentDepth], images[currentDepth - 1]] = [images[currentDepth - 1], images[currentDepth]];
    updateDepth();
    input_depth_backward.disabled = constants.currentImage.imageObj.depth === 0;
    input_depth_forward.disabled = constants.currentImage.imageObj.depth === images.length - 1;
  })
  
  // endregion form inputs event listeners
  
  
  $('[data-popup-close]').on('click', function (e) {
    $(this).closest('[data-popup]').fadeOut(350);
  });
  
  let ppiFormRow = $('.ppi-form-row');
  
  function removeHasWarning() {
    if (ppiFormRow.hasClass('has-warning')) {
      ppiFormRow.removeClass('has-warning');
    }
  }
  
  // region button delete in step 3
  getElement('stepThreeDeleteBtn').addEventListener('click', function (e) {
    $('[data-popup="popup-delete-cart-item"]').fadeIn(350);
  });
  $('[data-popup="popup-delete-cart-item"] [data-popup-yes]').on('click', function () {
    
    $(this).closest('[data-popup]').hide();
    showLoader('')
  
    removeHasWarning();
    try {
      deleteCartItem()
          .then(() => {
            console.log('cart item removed successfully')
            hideLoader(() => {
              window.location.href = `/${BASE_URL}create/`
            })
          })
          .catch(() => console.error('cart item removed failed'));
      // setTimeout(() => window.location.reload(), 1000);
    } catch (e) {
    }
  });
  
  // endregion button delete in step 3
  
  // region remove button in step 3
  getElement('stepThreeRemoveBtn').addEventListener('click', function (e) {
    $('[data-popup="popup-remove-image"]').fadeIn(350);
    
  });
  
  $('[data-popup="popup-remove-image"] [data-popup-yes]').on('click', function (e) {
    $(this).closest('[data-popup]').hide();
    
    removeHasWarning();
    $(getElement('mainImageSelector'))
        .find(':selected')
        .remove()
        .trigger('change');
    constants.currentImage.remove();
    constants.images.splice(constants.currentImage.imageObj.depth, 1);
    updateDepth();
    deleteImage(constants.currentImage.imageObj)
        .then(() => console.log('image removed successfully'))
        .catch(() => console.error('image removed failed'))
        .finally(() => constants.images.map(updateInputs));
  });
  
  // endregion remove button
  
  // region button upload in step3
  const btn_upload = getElement('stepThreeUploadBtn');
  const btn_upload_text = btn_upload.previousElementSibling;
  btn_upload.addEventListener("change", async (event) => {
    
    // Get the selected files
    const files = event.target.files;
    
    const uploadPromises = [];
    
    showLoader(btn_upload_text.dataset.loadingText)
    
    // Show the loader
    btn_upload_text.textContent = btn_upload_text.dataset.loadingText;
    // Disable the input
    btn_upload.disabled = true;
    
    
    for (const file of files) {
      const uploadPromise = (async () => {
        // Create an image element from the file
        await createImageElement(file);
      })().catch(error => {
        console.error(`Failed to upload ${file.name}`, error);
      });
      
      uploadPromises.push(uploadPromise);
    }
    
    try {
      await Promise.all(uploadPromises);
      constants.images.map(updateCanvas)
    } catch (error) {
      console.error('Some files failed to upload.', error);
    } finally {
      // Hide the loader and enable the input
      btn_upload.value = '';
      btn_upload_text.textContent = btn_upload_text.dataset.text;
      btn_upload.disabled = false;
      hideLoader()
    }
  });
  
  // endregion button upload in step3
  
  // region button save in step3
  const btn_save = getElement('stepThreeSaveBtn');
  const btn_save_text = btn_save.querySelector('.changing-text');
  btn_save.addEventListener("click", async function () {
    // Show the loader
    showLoader(btn_save_text.dataset.loadingText)
    btn_save_text.textContent = btn_save_text.dataset.loadingText;
    // Disable the input
    btn_save.disabled = true;
    saveCartItem()
        .then(() => {
          hideLoader(() => {
            window.location.href = `/${BASE_URL}cart/`
          })
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          // Hide the loader and enable the input
          hideLoader();
          btn_save_text.textContent = btn_save_text.dataset.text;
          btn_save.disabled = false;
        })
  });
  // endregion button save in step3
  
  window.addEventListener('resize', debounce(fixRuler, 1000))
  
  // region edit canvas page
  if (window.createFromJSON) {
    console.log('createFromJSON')
    generateCanvasFromJson(window.createFromJSON).then(async obj => {
      console.log(obj);
      const {default: stepOne} = await import('./step-1');
      stepOne(obj.productID);
    });
  }
  // endregion edit canvas page
  
  
}
