import {gsap} from "gsap";

export default function () {
  const menuItems = document.querySelectorAll('.menu-item-has-children');
  const mobileMedia = window.matchMedia('(max-width: 992px)');
  menuItems.forEach((menuItem) => {
    const menuItemBody = menuItem.querySelector('.sub-menu');
    menuItem?.addEventListener('click', (e) => {
      if (!mobileMedia.matches || !menuItemBody || e.target.classList.contains('header-link') || e.target.closest('.sub-menu,.menu-item-in-sub-menu a')) return;
      const isOpened = menuItem?.classList.toggle('menu-item-active');
      if (!isOpened) {
        gsap.to(menuItemBody, {height: 0});
      } else {
        gsap.to(Array.from(menuItems).map(otherMenuItem => {
          const otherMenuItemBody = otherMenuItem.querySelector('.sub-menu');
          if (otherMenuItemBody && menuItem !== otherMenuItem) {
            otherMenuItem?.classList.remove('menu-item-active');
            gsap.set(otherMenuItem, {zIndex: 1});
          }
          return otherMenuItemBody;
        }), {height: 0});
        gsap.set(menuItem, {zIndex: 2});
        gsap.to(menuItemBody, {height: 'auto'});
      }
    });
  });
}
