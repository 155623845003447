import {saveCartItem} from "../api";
import {fixRuler, updateCanvas, updateInputs} from "../canvas_DONE";
import {getElement} from "../html-elements-selector";
import {createImageElement} from "../image_DONE";
import {constants} from "../constants";
import {cm2pixel, hideLoader, showLoader} from "../utility_DONE";

export default function () {
  
  // Select the input element
  const fabricUploadInput = getElement('uploadFabric');
  const loaderContainer = document.querySelector('.upload-fabric-loader');
  
  const stepsWrapper = getElement('stepsWrapper');
  const stepThreeItem = getElement('stepThreeItem');
  const stepsLabel = getElement('stepsLabel');
  
  // Add event listener for 'change' event
  fabricUploadInput?.addEventListener('change', async (event) => {
    
    
    // Get the selected files
    const files = event.target.files;
    
    // Show the loader
    showLoader("Uploading...");
    
    // Disable the input
    fabricUploadInput.disabled = true;
    await saveCartItem()
    
    const uploadPromises = [...files]
        .filter(file => file.size < 350 * 1024 * 1024)
        .map(file =>
            createImageElement(file).catch(error => {
              console.error(`Failed to upload ${file.name}`, error);
            })
        );
    try {
      await Promise.all(uploadPromises,);
      
      constants.images.map(imageElement => {
        imageElement.style.width = cm2pixel(imageElement.imageObj.cm_width) + 'px';
        imageElement.style.height = cm2pixel(imageElement.imageObj.cm_height) + 'px';
        imageElement.style.setProperty("--master-width", cm2pixel(imageElement.imageObj.cm_width) + 'px');
        imageElement.style.setProperty("--master-height", cm2pixel(imageElement.imageObj.cm_height) + 'px');
        updateInputs(imageElement)
        updateCanvas(imageElement)
      })
      
      constants.created = true;
      saveCartItem().catch(console.error)
      hideLoader(() => {
        stepThreeItem?.classList.add('active');
        stepsLabel?.classList.add('step-3-active');
        stepsWrapper?.classList.add('taps-3-content-active');
        stepsWrapper?.classList.remove('taps-2-content-active');
        fixRuler();
      });
      
      const numElement = document.querySelector(".orders_tab .num");
      const currentNum = +numElement.textContent;
      numElement.textContent = currentNum + 1;
      
    } catch (error) {
      console.error('Some files failed to upload.', error);
      hideLoader();
    } finally {
      fabricUploadInput.disabled = false;
    }
  });
  window.constants = constants;
  
}

