import {constants} from "./constants";
import {getElement} from "./html-elements-selector";
import {cm2pixel, pixel2cm} from "./utility_DONE";
import $ from "jquery";

export function createTransformedCanvas(image, pixelWidth, pixelHeight) {
  const transformedCanvas = document.createElement('canvas');
  transformedCanvas.width = pixelWidth;
  transformedCanvas.height = pixelHeight;
  const ctx = transformedCanvas.getContext('2d');
  ctx.clearRect(0, 0, transformedCanvas.width, transformedCanvas.height);
  ctx.setTransform(1, 0, 0, 1, 0, 0);
  ctx.drawImage(image, 0, 0, transformedCanvas.width, transformedCanvas.height);
  return transformedCanvas;
}

export function updateCanvas(imageElement) {
  const {canvas, imageObj} = imageElement
  const {dpr, repeatStylesCanvasFunctions} = constants;
  const rect = imageElement.getBoundingClientRect();
  const ctx = canvas.getContext("2d");
  
  imageElement.imageObj.pixel_offset_x = cm2pixel(imageElement.imageObj.offset_x)
  imageElement.imageObj.pixel_offset_y = cm2pixel(imageElement.imageObj.offset_y)
  
  imageElement.style.left = imageElement.imageObj.pixel_offset_x + 'px';
  imageElement.style.top = imageElement.imageObj.pixel_offset_y + 'px';
  // Set the canvas size
  canvas.width = rect.width * dpr;
  canvas.height = rect.height * dpr;
  
  // Scale the canvas by the device pixel ratio
  ctx.scale(dpr, dpr);
  rotateFlip(imageElement);
  try {
  repeatStylesCanvasFunctions[imageObj.repeat_style](imageElement);
  }catch (e) {
    console.log(e);
  }
  
}

export function updateDepth() {
  for (let i = 0; i < constants.images.length; i++) {
    constants.images[i].style.zIndex = i;
    constants.images[i].imageObj.depth = i;
  }
}

export function rotateFlip(imageElement) {
  const canvas = imageElement.transformedCanvas;
  const ctx = canvas.getContext('2d');
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  
  const transforms = {
    '90-h': () => ctx.setTransform(0, -1, -1, 0, canvas.width, canvas.height),
    '270-v': () => ctx.setTransform(0, -1, -1, 0, canvas.width, canvas.height),
    '90-v': () => ctx.setTransform(0, 1, 1, 0, 0, 0),
    '270-h': () => ctx.setTransform(0, 1, 1, 0, 0, 0),
    '0-h': () => ctx.setTransform(-1, 0, 0, 1, canvas.width, 0),
    '180-v': () => ctx.setTransform(-1, 0, 0, 1, canvas.width, 0),
    '0-v': () => ctx.setTransform(1, 0, 0, -1, 0, canvas.height),
    '180-h': () => ctx.setTransform(1, 0, 0, -1, 0, canvas.height),
    '270-n': () => ctx.setTransform(0, -1, 1, 0, 0, canvas.height),
    '180-n': () => ctx.setTransform(-1, 0, 0, -1, canvas.width, canvas.height),
    '90-n': () => ctx.setTransform(0, 1, -1, 0, canvas.width, 0),
    'default': () => ctx.setTransform(1, 0, 0, 1, 0, 0)
  };
  
  const isRotated = ['90', '270'].includes(imageElement.imageObj.rotate);
  const key = `${imageElement.imageObj.rotate}-${imageElement.imageObj.flip}`;
  
  isRotated && ctx.rotate(Math.PI / 2);
  (transforms[key] || transforms['default'])();
  
  // Calculate appropriate dimensions depending on the rotation
  const drawWidth = isRotated ? canvas.height : canvas.width;
  const drawHeight = isRotated ? canvas.width : canvas.height;
  
  ctx.drawImage(imageElement.image, 0, 0, drawWidth, drawHeight);
}

export function updateInputs({imageObj}) {
  document.querySelectorAll('form.form-create input , form.form-create textarea').forEach(input => {
    const inputName = input.getAttribute('name');
    if (inputName && inputName in imageObj) {
      input.value = imageObj[inputName];
    }
  });
  document.querySelectorAll('form.form-create select').forEach(input => {
    const inputName = input.getAttribute('name');
    if (inputName && inputName in imageObj) {
      $(input).val(imageObj[inputName]).trigger('change');
    }
  });
  getElement('input_depth_backward').disabled = imageObj.depth === 0;
  getElement('input_depth_forward').disabled = imageObj.depth === constants.images.length - 1;
  const height = Math.max(...constants.images.map(image => image.getBoundingClientRect().bottom));
  const imagesContainer = getElement('imagesContainer');
  constants.layoutHeight = height - imagesContainer.getBoundingClientRect().top + 50;
  imagesContainer.style.height = constants.layoutHeight + 'px';
  getElement('ppiFormRow').classList.toggle('has-warning', imageObj.ppi < constants.minPPI)
  getElement('rulerY').innerHTML = '<li></li>'.repeat(~~(pixel2cm(constants.layoutHeight) / 10))
  
}


export function updateDimensions(imageElement, cmWidth, cmHeight) {
  const pxWidth = cm2pixel(cmWidth.toFixed(1))
  const pxHeight = cm2pixel(cmHeight.toFixed(1))
  
  imageElement.style.setProperty("--master-width", pxWidth + 'px');
  imageElement.style.setProperty("--master-height", pxHeight + 'px');
  imageElement.imageObj.cm_width = cmWidth.toFixed(1);
  imageElement.imageObj.cm_height = cmHeight.toFixed(1);
  imageElement.imageObj.ppi = (imageElement.imageObj.pixel_width / imageElement.imageObj.cm_width * 2.54).toFixed(1);
  getElement('ppiFormRow').classList.toggle('has-warning', imageElement.imageObj.ppi < constants.minPPI)
  if (cm2pixel(cmWidth) > imageElement.clientWidth) {
    imageElement.style.width = cm2pixel(cmWidth.toFixed(1)) + 'px';
    imageElement.imageObj.cm_repeat_x = cmWidth.toFixed(1);
  }
  if (cm2pixel(cmHeight) > imageElement.clientHeight) {
    imageElement.style.height = cm2pixel(cmHeight.toFixed(1)) + 'px';
    imageElement.imageObj.cm_repeat_y = cmHeight.toFixed(1);
    
  }
}

export function updateRepeatX(imageElement, cmWidth) {
  imageElement.style.width = cm2pixel(cmWidth) + 'px';
  imageElement.imageObj.cm_repeat_x = cmWidth.toFixed(1);
  
  if (cmWidth < imageElement.imageObj.cm_width) {
    const cmHeight = cmWidth / imageElement.imageObj.aspect_ratio;
    updateDimensions(imageElement, cmWidth, cmHeight);
  }
}

export function updateRepeatY(imageElement, cmHeight) {
  imageElement.style.height = cm2pixel(cmHeight) + 'px';
  imageElement.imageObj.cm_repeat_y = cmHeight.toFixed(1);
  
  if (cmHeight < imageElement.imageObj.cm_height) {
    const cmWidth = cmHeight * imageElement.imageObj.aspect_ratio;
    updateDimensions(imageElement, cmWidth, cmHeight);
  }
}

export function fixRuler() {
  
  let fabricWidth = constants.fabricWidth;
  let layoutWidth = getElement('layoutWidthElement').clientWidth;
  let tabsBlock = getElement('tabsBlock');
  
  constants.cmFactor = layoutWidth / fabricWidth;
  
  tabsBlock.style.setProperty("--factor", constants.cmFactor + 'px');
  constants.images.map(imageElement => {
    
    imageElement.imageObj.pixel_offset_y = cm2pixel(imageElement.imageObj.offset_y);
    imageElement.imageObj.pixel_offset_x = cm2pixel(imageElement.imageObj.offset_x);
    
    imageElement.style.width = cm2pixel(imageElement.imageObj.cm_repeat_x) + 'px';
    imageElement.style.height = cm2pixel(imageElement.imageObj.cm_repeat_y) + 'px';
    
    updateDimensions(imageElement, +imageElement.imageObj.cm_width, +imageElement.imageObj.cm_height)
    updateCanvas(imageElement)
    updateInputs(constants.currentImage)
  })
  

  getElement('rulerX').innerHTML = '<li></li>'.repeat(~~(fabricWidth / 10))
  getElement('rulerY').innerHTML = '<li></li>'.repeat(~~(pixel2cm(constants.layoutHeight) / 10))
}
