import $ from "jquery";

export default function (select2) {
  
  $(document).ready(function () {
    let select2 = $('#printOptionsFilter')
    select2.select2({
      placeholder: "Choose a print option",
      allowClear: true
    });
    select2.prepend('<option value="" disabled selected style="display:none;">Choose a print option</option>');
  });
  
  
}

