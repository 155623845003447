import './styles/style.scss';
import {gsap} from "gsap";
import {initBlocks} from './blocks';
import {getHeightOfViewPort} from './scripts/functions/getHeightOfViewPort';
import {initModal} from './scripts/site-functionality/modal_DONE';
import stepOne from './scripts/site-functionality/tab-steps/step-1';
import stepTwo from './scripts/site-functionality/tab-steps/step-2';
import stepThree from "./scripts/site-functionality/tab-steps/step-3";
import header from "./scripts/site-functionality/header";
import select2 from "./scripts/site-functionality/select2";


const reInvokableFunction = async (container = document) => {
  try {
    header();
    select2();
    getHeightOfViewPort();
    initModal();
    
    if (container.querySelector('.tabs_block.all')) {
      stepOne();
      stepTwo();
    }
    if (container.querySelector('.tabs_block')) {
      stepThree();
    }
    document.documentElement.style.setProperty('--adjusted-viewport-width', `${window.innerWidth - 4}`);
  } catch (e) {
    console.log(e);
  }
};
let loaded = false;

async function onLoad() {
  if (document.readyState === 'interactive' && !loaded) {
    loaded = true;
    
    await reInvokableFunction();
    window.dispatchEvent(new Event('bodyloaded'))
    document.body.classList.add('loaded');
  }
}

onLoad();

document.onreadystatechange = function () {
  onLoad();
};

