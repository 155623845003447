import {constants} from "./constants";
import {getFinalJson} from "./image_DONE";
import {canvasToBlob} from "./utility_DONE";

const handleError = (error, source) => {
  console.error(`Error uploading to ${source}:`, error);
};

export async function saveCartItem() {
  if (!constants.productID) {
    throw new Error('Please select a product first.');
  }
  const finalJson = await getFinalJson();
  const formData = new FormData();
  formData.append('action', 'add_or_update_cart_item');
  formData.append('product_id', constants.productID);
  constants.cartItemID && formData.append('cart_item_key', constants.cartItemID);
  formData.append('cart_item_data', JSON.stringify(finalJson.finalJsonForWP));
  formData.append('cart_item_data_dropbox', JSON.stringify(finalJson.finalJsonForDropbox));
  formData.append('fabric_length', constants.fabricLength);
  formData.append('final_thumbnail_url', constants.finalThumbnailURL);
  formData.append('print_option', JSON.stringify(constants.print_option));
  formData.append('_ajax_nonce_handle_cart', theme_ajax_object._ajax_nonce_handle_cart);
  
  try {
    const response = await fetch(theme_ajax_object.ajax_url, {
      method: 'POST',
      body: formData,
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    });
    const {data, success} = await response.json();
    
    if (success) {
      const {cart_item_key, uuid} = data;
      constants.cartItemID = cart_item_key;
      constants.uuid = uuid;
    } else {
      throw new Error('Error adding product to cart.');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

export async function deleteCartItem() {
  
  try {
    // delete from cart
    const formData = new FormData();
    formData.append('cart_id', constants.cartID);
    formData.append('cart_uuid', constants.uuid);
    formData.append('cart_item_key', constants.cartItemID);
    formData.append('action', 'remove_cart_item');
    formData.append('_ajax_nonce_handle_cart', theme_ajax_object._ajax_nonce_handle_cart);
    
    try {
      let response = await fetch(theme_ajax_object.ajax_url, {
        method: 'POST',
        body: formData
      });
      
      if (!response.ok) {
        throw new Error('Network response was not ok' + response.statusText);
      }
      
      return true;
    } catch (error) {
      console.error('Error during fetch operation: ', error);
    }
    
  } catch (e) {
    console.error(e)
  }
}


export async function uploadImage({full_file_name, file_name_without_extension, imageFile}) {
  if (!full_file_name) {
    console.error("No image object provided");
    throw new Error("Image object is required for uploadImage");
  }
  
  try {
    return await uploadToDropbox(imageFile, full_file_name, file_name_without_extension);
  } catch (e) {
    handleError(e, 'Dropbox');
    return false
  }
  
}

export async function deleteImage({wp_url, dropbox_url}) {
  return await Promise.all([deleteFromDropbox(dropbox_url), deleteImageFromWordPress(wp_url)])
}

export async function uploadToWordPress(image, imageNameWithoutExtension, compressionRatio = 0.5) {
  const canvas = document.createElement("canvas");
  canvas.width = image.width * compressionRatio;
  canvas.height = image.height * compressionRatio;
  
  let ctx = canvas.getContext("2d");
  ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
  const blob = await canvasToBlob(canvas);
  const imageName = imageNameWithoutExtension + '.webp';
  const formData = new FormData();
  formData.append('image', new File([blob], imageName, {type: 'image/webp'}));
  formData.append('image_name', imageName);
  formData.append('image_path', `/cat/cart/${constants.cartID}/${constants.uuid}/`);
  formData.append('action', 'upload_to_wp');
  formData.append('_ajax_nonce_handle_image', theme_ajax_object._ajax_nonce_handle_image);
  
  try {
    let response = await fetch(theme_ajax_object.ajax_url, {
      method: 'POST',
      body: formData
    });
    
    if (!response.ok) {
      throw new Error('Network response was not ok' + response.statusText);
    }
    
    return (await response.json())?.data;
  } catch (error) {
    console.error('Error during fetch operation: ', error);
    throw error;
  }
}

async function deleteFromDropbox(path) {
  const formData = new FormData();
  formData.append('path', path);
  formData.append('action', 'delete_from_dropbox');
  formData.append('_ajax_nonce_handle_image', theme_ajax_object._ajax_nonce_handle_image);
  
  try {
    let response = await fetch(theme_ajax_object.ajax_url, {
      method: 'POST',
      body: formData
    });
    
    if (!response.ok) {
      throw new Error('Network response was not ok' + response.statusText);
    }
    
    return true;
  } catch (error) {
    console.error('Error during fetch operation: ', error);
    throw error;
  }
}

async function deleteImageFromWordPress(imageUrl) {
  const formData = new FormData();
  formData.append('image_url', imageUrl);
  formData.append('action', 'remove_image_from_wp');
  formData.append('_ajax_nonce_handle_image', theme_ajax_object._ajax_nonce_handle_image);
  
  try {
    let response = await fetch(theme_ajax_object.ajax_url, {
      method: 'POST',
      body: formData
    });
    
    if (!response.ok) {
      throw new Error('Network response was not ok' + response.statusText);
    }
    
    return true;
  } catch (error) {
    console.error('Error during fetch operation: ', error);
    throw error;
  }
}

async function deleteFolderFromWordPress(path) {
  const formData = new FormData();
  formData.append('folder_url', path);
  formData.append('action', 'remove_folder_from_wp');
  formData.append('_ajax_nonce_handle_image', theme_ajax_object._ajax_nonce_handle_image);
  
  try {
    let response = await fetch(theme_ajax_object.ajax_url, {
      method: 'POST',
      body: formData
    });
    
    if (!response.ok) {
      throw new Error('Network response was not ok' + response.statusText);
    }
    
    return true;
  } catch (error) {
    console.error('Error during fetch operation: ', error);
    throw error;
  }
}

export async function uploadToDropbox_old(imageFile, imageName, file_name_without_extension, autorename = true) {
  
  const formData = new FormData();
  formData.append('image', imageFile);
  formData.append('image_name', imageName);
  formData.append('webp_image_name', file_name_without_extension + '.webp');
  formData.append('autorename', autorename);
  formData.append('image_path', `/cat/cart/${constants.cartID}/${constants.uuid}/`);
  formData.append('action', 'upload_to_dropbox');
  formData.append('_ajax_nonce_handle_image', theme_ajax_object._ajax_nonce_handle_image);
  
  try {
    let response = await fetch(theme_ajax_object.ajax_url, {
      method: 'POST',
      body: formData
    });
    
    if (!response.ok) {
      throw new Error('Network response was not ok' + response.statusText);
    }
    const urls = await response.json()
    return {dropbox_url: urls?.data?.dropbox_url?.path_lower, wp_url: urls?.data?.wp_url};
  } catch (error) {
    console.error('Error during fetch operation: ', error);
    throw error;
  }
}

export async function uploadToDropbox(imageFile, imageName, file_name_without_extension, autorename = true) {
  const CHUNK_SIZE = 99 * 1024 * 1024;
  const totalSize = imageFile.size;
  const chunks = Math.ceil(totalSize / CHUNK_SIZE);
  let offset = 0;
  let sessionId = null;
  let dropbox_url = '';
  let wp_url = '';
  let ppi = 0;
  
  const uploadChunk = async (chunk, isLastChunk) => {
    const formData = new FormData();
    formData.append('file', chunk);
    formData.append('chunk', Math.floor(offset / CHUNK_SIZE)); // current chunk index
    formData.append('chunks', chunks); // total chunks
    formData.append('offset', offset); // offset in bytes
    formData.append('is_last_chunk', isLastChunk); // offset in bytes
    formData.append('image_name', imageName);
    formData.append('webp_image_name', file_name_without_extension + '.webp');
    formData.append('autorename', autorename);
    formData.append('image_path', `/cat/cart/${constants.cartID}/${constants.uuid}/`);
    formData.append('action', 'upload_to_dropbox');
    formData.append('_ajax_nonce_handle_image', theme_ajax_object._ajax_nonce_handle_image);
    
    if (sessionId) {
      formData.append('session_id', sessionId);
    }
    
    const response = await fetch(theme_ajax_object.ajax_url, {
      method: 'POST',
      body: formData
    });
    
    if (!response.ok) {
      throw new Error('Network response was not ok: ' + response.statusText);
    }
    
    const {data} = await response.json();
    if (data.session_id) {
      sessionId = data.session_id;
    }
    if (isLastChunk) {
      dropbox_url = data?.dropbox_url?.path_lower;
      wp_url = data?.wp_url;
      ppi = data?.ppi;
    }
    
  };
  
  while (offset < totalSize) {
    const nextOffset = Math.min(offset + CHUNK_SIZE, totalSize);
    const chunk = imageFile.slice(offset, nextOffset);
    const isLastChunk = nextOffset === totalSize;
    await uploadChunk(chunk, isLastChunk);
    offset = nextOffset;
  }
  return {dropbox_url, wp_url, ppi};
}
